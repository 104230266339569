<template>
  <div class="modal-card">
    <header class="modal-card-head">Search a color</header>
    <section class="modal-card-body">
      <b-input v-model="color" ref="autocomplete"></b-input>
      <b-tabs>
        <b-tab-item label="Pantone">
          <ul class="h-64 overflow-y-scroll">
            <li
              class="flex items-center my-2 cursor-pointer"
              v-for="(color, index) in pantones"
              :key="index"
              @click="attachColorToCoordinates(color)"
            >
              <div class="h-10 w-10" :style="{ background: color.hex }"></div>
              <div class="w-1/2 ml-4">
                <span class="is-capitalized">{{ color.name }}</span>
              </div>
              <div class="w-1/4 ml-4">{{ color.code }}</div>
            </li>
          </ul>
        </b-tab-item>
        <b-tab-item label="Ral">
          <ul class="h-64 overflow-y-scroll">
            <li
              class="flex items-center my-2 cursor-pointer"
              v-for="(color, index) in rals"
              :key="index"
              @click="attachColorToCoordinates(color)"
            >
              <div class="h-10 w-10" :style="{ background: color.hex }"></div>
              <div class="w-1/2 ml-4">
                <span class="is-capitalized">{{ color.name }}</span>
              </div>
              <div class="w-1/4 ml-4">{{ color.code }}</div>
            </li>
          </ul>
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import { Events } from "@/events.js";
export default {
  data: () => ({
    color: ""
    // emit: ''
  }),
  methods: {
    attachColorToCoordinates(color) {
      Events.$emit(this.$parent.props.emit, color);
      this.$parent.close();
    }
  },
  computed: {
    rals() {
      return this.$store.getters.filterRalColors(this.color);
    },
    pantones() {
      return this.$store.getters.filterPantoneColors(this.color);
    }
  },
  mounted() {
    this.$refs["autocomplete"].focus();
    // this.emit = this.$parent.props
  }
};
</script>
