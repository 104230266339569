<template>
  <div class="has-background-white is-relative" v-if="image">
    <div class="px-2">
      <div class="-mx-4 flex">
        <div class="px-2 w-1/2" :class="{ 'w-3/5': !edit }">
          <div class="image-container is-relative">
            <img
              :src="edit ? image.thumb : image.full"
              alt="ref"
              :class="{ crosshair: edit }"
              @click.prevent="onSelectCoordinates()"
            />
            <span
              v-for="(coordinate, index) in coordinates"
              :key="index"
              :style="{
                position: 'absolute',
                left: coordinate.x + '%',
                top: coordinate.y + '%'
              }"
              class="has-background-white shadow h-4 w-4 is-size-7 flex items-center justify-center shadow"
              >{{ index + 1 }}</span
            >
          </div>
        </div>
        <div class="p-2 pr-4 w-1/2" :class="{ 'w-2/5': !edit }">
          <div v-if="!edit" class="p-4 flex justify-end">
            <b-button
              icon-left="printer"
              size="is-small"
              type=""
              @click="printReference()"
              >Print</b-button
            >
            <b-button
              icon-left="close"
              size="is-small"
              type=""
              class="ml-2"
              @click="$parent.close()"
              >Close</b-button
            >
          </div>
          <div class="p-2 flex items-center my-2">
            <div class="w-1/12 px-1 is-size-7">N</div>
            <div class="w-10/12 px-1">
              <b-input
                v-if="edit"
                v-model="reference.name"
                size="is-small"
                placeholder="e.g. side view, close up view"
              ></b-input>
              <input
                v-else
                type="text"
                :value="reference.name"
                readonly
                :disabled="!edit"
                class="appearance:none is-size-7 w-full bg-transparent p-2"
              />
            </div>
            <div class="w-auto flex-grow"></div>
            <b-dropdown
              :triggers="['contextmenu']"
              aria-role="list"
              v-if="edit"
            >
              <b-button
                icon-left="trash-can"
                size="is-small"
                type=""
                class="has-text-grey"
                slot="trigger"
              ></b-button>
              <b-dropdown-item
                aria-role="listitem"
                @click="removeReference(index, reference.id)"
                >Remove Reference</b-dropdown-item
              >
              <b-dropdown-item aria-role="listitem">Cancel</b-dropdown-item>
            </b-dropdown>
          </div>
          <ul class="p-2 pt-0">
            <li
              v-for="(coordinate, index) in coordinates"
              :key="index"
              class="flex items-center my-2"
            >
              <div class="w-1/12 px-1 is-size-7">{{ index + 1 }}</div>
              <div class="w-4/12 px-1">
                <b-input
                  v-if="edit"
                  size="is-small"
                  :ref="`${coordinate.x}-${coordinate.y}`"
                  v-model="coordinate.label"
                ></b-input>
                <input
                  v-else
                  type="text"
                  :value="coordinate.label"
                  readonly
                  :disabled="!edit"
                  class="appearance:none is-size-7 w-full bg-transparent py-2"
                />
              </div>
              <div class="w-7/12 px-1 flex items-center flex-wrap">
                <div
                  v-if="edit"
                  class="h-4 w-4 shadow-xs"
                  :style="{ background: coordinate.color.hex }"
                ></div>
                <img
                  v-else
                  :src="colorPlaceholder(coordinate.color.hex)"
                  :alt="coordinate.color.hex"
                  class="h-4 w-4 shadow-xs"
                />
                <span class="is-capitalized is-size-7 w-1/3 px-2">{{
                  coordinate.color.code
                }}</span>
                <span class="is-capitalized is-size-7 flex-grow px-2">{{
                  coordinate.color.name
                }}</span>
                <div class="-mx-1" v-if="edit">
                  <b-dropdown :triggers="['contextmenu']" aria-role="list">
                    <b-button
                      icon-left="close"
                      size="is-small"
                      type=""
                      class="has-text-grey"
                      slot="trigger"
                    ></b-button>
                    <b-dropdown-item
                      aria-role="listitem"
                      @click="removeCoordinates(coordinates, index)"
                      >Remove Marker</b-dropdown-item
                    >
                    <b-dropdown-item aria-role="listitem"
                      >Cancel</b-dropdown-item
                    >
                  </b-dropdown>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Events } from "@/events.js";
import ColorsModal from "@/components/admin/ColorsModal";
export default {
  props: {
    reference: {
      type: Object,
      required: true
    },
    emit: {
      type: String
    },
    index: {
      type: Number
    },
    edit: {
      type: Boolean
    }
  },
  data: () => ({
    newCoordinates: {},
    coordinates: [],
    image: null
  }),
  computed: {
    colors() {
      return this.$store.state.products.colors;
    }
  },
  watch: {
    coordinates() {
      this.$emit("coordinates-updated", this.coordinates);
    }
  },
  methods: {
    printReference() {
      window.print();
    },
    colorPlaceholder(hex) {
      return `https://via.placeholder.com/150/${_.trim(hex, "#")}/${_.trim(
        hex,
        "#"
      )}/-`;
    },
    getImage() {
      this.$store
        .dispatch("getFile", { id: this.reference.file_id })
        .then(res => {
          this.image = res.data;
        });
    },
    onSelectCoordinates() {
      if (!this.edit) {
        return;
      }
      let x = _.ceil(((event.layerX - 15) / event.target.clientWidth) * 100);
      let y = _.ceil(((event.layerY - 15) / event.target.clientHeight) * 100);
      this.newCoordinates = { x: x, y: y };
      this.colorsModal();
    },
    pushCoordinates(event) {
      if (_.findIndex(this.coordinates, this.newCoordinates) < 0) {
        this.newCoordinates.label = "";
        this.newCoordinates.color = event;
        this.coordinates.push(this.newCoordinates);
      }
    },
    removeCoordinates(coordinates, index) {
      coordinates.splice(index, 1);
    },
    removeReference(index, id) {
      this.$emit("remove-reference", { index: index, id: id });
    },
    colorsModal() {
      this.$buefy.modal.open({
        parent: this,
        component: ColorsModal,
        props: { emit: this.emit },
        hasModalCard: true,
        trapFocus: true
      });
    }
  },
  mounted() {
    this.getImage();
    Events.$on(this.emit, this.pushCoordinates);
    this.coordinates = this.reference.coordinates;
  }
};
</script>

<style lang="scss" scoped>
.crosshair {
  cursor: crosshair;
}
</style>
