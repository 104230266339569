<template>
  <div class="min-h-screen flex">
    <Sidebar />
    <div class="w-2/12"></div>
    <div class="p-4 w-10/12">
      <div class="flex items-center mb-4">
        <div class="is-size-4">{{ $route.name }}</div>
      </div>
      <div class="flex items-center justify-between mb-4">
        <div class="flex items-center"></div>
        <div @keyup.enter="onSearch">
          <b-input
            v-model="search"
            icon="magnify"
            placeholder="Search"
          ></b-input>
        </div>
      </div>
      <div class="px-2 is-relative">
        <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
        <div
          @scroll="onScroll"
          ref="references-container"
          class="-mx-4 flex flex-wrap references-container"
        >
          <div
            class="w-1/5 px-2 mb-4 cursor-pointer"
            v-for="(reference, index) in data"
            :key="index"
            @click="showColorReferenceModal(reference)"
          >
            <div class="reference-item">
              <b-image
                :src="reference.file.thumb"
                :alt="reference.name"
                ratio="1by1"
                lazy
              ></b-image>
              <p class="bg-gray-200 p-2 break-all">{{ reference.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/admin/Sidebar";
import store from "@/store";
import ColorReference from "@/components/admin/ColorReference";
export default {
  components: {
    Sidebar
  },
  data: () => ({
    checkable: false,
    checkedRows: []
  }),
  computed: {
    data() {
      return this.$store.state.references.data;
    },
    search: {
      get() {
        return this.$store.state.references.search;
      },
      set(newValue) {
        store.commit("setReferencesSearch", newValue);
      }
    },
    isLoading() {
      return store.getters.isLoading && !store.getters.isRouting ? true : false;
    }
  },
  watch: {
    search: _.debounce(function() {
      if (!this.search) this.getReferences();
    }, store.getters.delay)
  },
  methods: {
    showColorReferenceModal(reference) {
      this.$buefy.modal.open({
        parent: this,
        component: ColorReference,
        props: { reference: reference, edit: false },
        hasModalCard: true,
        trapFocus: true,
        width: 2000
      });
    },
    userHasPermission(data) {
      return store.getters.userHasPermission(data);
    },
    getReferences() {
      store.dispatch(this.$route.meta.dispatch);
    },
    onScroll() {
      let currentScroll =
        this.$refs["references-container"].scrollHeight -
        this.$refs["references-container"].scrollTop;
      let endScroll = this.$refs["references-container"].offsetHeight;
      if (_.floor(currentScroll) === endScroll) {
        store.commit("incrementReferencesTake");
        this.getReferences();
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.dispatch) {
      store.dispatch(to.meta.dispatch).then(() => {
        next();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.references-container {
  width: 100%;
  height: 80vh;
  max-height: calc(80vh);
  overflow-y: scroll;
}
.reference-item {
  border: 2px solid rgba(0, 0, 0, 0.1);
}
</style>
